<script lang="ts">
	import * as config from '$lib/config';
	import Header from './Header.svelte';
	import Footer from './Footer.svelte';
	import 'open-props/style';
	import 'open-props/normalize.min.css';
	import '../styles/theme.css';
	import '../styles/reactive-styles.css';
	import { SvelteToast } from '@zerodevx/svelte-toast';
	import AnalyticsLogging from '$lib/utility-components/AnalyticsLogging.svelte';

	export const debugMode = false;
</script>

<AnalyticsLogging />

<svelte:head><title>{config.title}</title></svelte:head>
<div class="app">
	<Header />

	<main>
		<slot />
	</main>

	<Footer />
</div>

{#if debugMode}
	<SvelteToast />
{/if}

<style>
	.app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	main {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: var(--size-3);
		width: 100%;
		max-width: 64rem;
		margin: 0 auto;
		box-sizing: border-box;
		color: var(--text-1);
		font-family: var(--font-family-1);
	}
</style>
