<script lang="ts">
	import { page } from '$app/stores';
</script>

<header>
	<a class="home" href="/">michaelekins.co.uk</a>
	<nav>
		<ul>
			<li aria-current={$page.url.pathname === '/articles' ? 'page' : undefined}>
				<a href="/articles">Articles</a>
			</li>
			<li aria-current={$page.url.pathname.startsWith('/projects') ? 'page' : undefined}>
				<a href="/projects">Projects</a>
			</li>
			<li aria-current={$page.url.pathname.startsWith('/contact') ? 'page' : undefined}>
				<a href="/contact">Contact Me</a>
			</li>
		</ul>
	</nav>
</header>

<style lang="postcss">
	header {
		background: var(--brand);
		color: var(--brand-text);
		line-height: var(--font-lineheight-1);
		letter-spacing: var(--font-letterspacing-3);

		list-style: none;
		text-align: center;
		font-family: var(--font-family-1);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.home {
		position: absolute;
		left: var(--size-5);
		text-decoration: underline;
		text-underline-offset: var(--size-2);
	}

	.home:hover {
		text-decoration: underline;
		text-underline-offset: var(--size-2);
	}

	li {
		display: inline-block;
		margin: var(--size-3) var(--size-7) var(--size-3) var(--size-7);
		width: var(--size-10);
		white-space: nowrap;
		text-transform: uppercase;
	}

	a {
		color: var(--brandtext-1);
		text-decoration: none;
		transition: color 0.2s;
	}

	a:hover {
		color: var(--accent-1);
		font-weight: var(--font-weight-7);
		text-decoration: none;
	}

	@media (max-width: 768px) {
		header ul,
		header a {
			font-size: var(--font-size-00);
		}

		header li {
			margin: var(--size-2) var(--size-2) var(--size-2) var(--size-2) !important;
			width: var(--size-7) !important;
		}

		header ul {
			padding-inline-start: var(--size-0);
		}

		.home {
			left: var(--size-2);
			text-underline-offset: var(--size-1);
		}

		.home:hover {
			text-underline-offset: var(--size-1);
		}
	}
</style>
