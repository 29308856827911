<script lang="ts">
	import { page } from '$app/stores';
</script>

<footer>
	<p>&copy 2015 - {new Date().getFullYear()} Michael Ekins<br />All rights reserved</p>
</footer>

<style lang="postcss">
	footer {
		grid-column: 1/-1;
		background: var(--brand);
		color: var(--brand-text);
		margin-top: var(--size-0);
		text-align: left;
		line-height: var(--font-lineheight-1);
		font-size: var(--font-size-00);
		padding: var(--size-1);
		bottom: 0;
		max-height: var(--size-10);
		width: 100%;
	}
	footer p {
		margin-left: var(--size-5);
	}

	@media (min-width: 480px) {
		footer {
			padding: 12px 0;
		}
	}

	@media (max-width: 768px) {
		footer p {
			font-size: var(--font-size-00);
		}
	}
</style>
